import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Animal Selctor`}</h1>
    <p>{`Following `}<a href="https://material.io/guidelines/motion/material-motion.html" target="_blank">{`Google's material design and motion guidelines`}</a>{` I built a little demo to show transform 3d events in action.`}</p>
    <p>{`Using React and Styled-Components library I whipped together a full screen that flys in based on the selected animal toggling the direction from left to right as the animal card leaves the screen.`}</p>
    <p>{`Check out the `}<a href="https://czaas.github.io/animal-selector/" target="_blank">{`demo`}</a>{`.`}</p>
    <div className="pure-g">
  <div className="pure-u-1 pure-u-md-1-2">
    <h3>Landing Screen</h3>
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fanimal-selector.jpg?alt=media&token=71667923-1cda-46ff-8287-bb7d1c33d70e" alt="Landing screen" />
  </div>
  <div className="pure-u-1 pure-u-md-1-2">
    <h3>Animal Selected</h3>
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fanimal-selector-open.jpg?alt=media&token=e0c02d24-3884-4205-947e-ef8acbeda845" alt="Animal Selected" />
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      